<template>
  <v-container>
    <v-card class="elevation-12 ma-2" v-for="user in users" :key="user._id">
      <v-card-text>
        ID: {{ user._id }} <br />
        Username: {{ user.username }} <br />
        Name: {{ user.name }} <br />
        Email: {{ user.email }} <br />
        Roles: {{ user.roles }} <br />
        <span v-for="role in roles" :key="role">
          <v-btn class="ma-2" color="green" v-if="!user.roles.includes(role)" @click="updateRole(user._id, role)">Give {{role}} </v-btn>
          <v-btn class="ma-2" color="red" v-if="user.roles.includes(role)" @click="updateRole(user._id, role)">Revoke {{role}} </v-btn>
        </span>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import Axios from "axios";
import { getDomainRoot } from "../../js/utilities.js";
export default {
  props: {},
  data: () => ({
    users: [],
    roles: []
  }),
  created() {
    this.getUsers();
  },
  methods: {

    getUsers() {
      console.log("Attempting Get Users");
      var self = this;
      const config = {
        withCredentials: true
      };
      Axios.get(getDomainRoot("/api/users"), config)
        .then(function(response) {
          if (response.data.success == true) {
            self.users = response.data.users
            self.roles = response.data.roles
          } else {
            alert(response.data.message);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    updateRole(id, role) {
      console.log("Attempting Update Role");
      var self = this;
      const config = {
        withCredentials: true
      };
      Axios.post(getDomainRoot("/api/users/role"), { _id: id, role }, config)
        .then(function(response) {
          if (response.data.success == true) {
            self.getUsers()
          } else {
            alert(response.data.message);
          }
        })
        .catch(function(error) {
          console.log(error);
        });

    }
  }
};
</script>