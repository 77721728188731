// Core Components
import PaintViewerModal from '../components/PaintViewerModal.vue';
import PaintEditorModal from '../components/PaintEditorModal.vue';

function setupComponents(Vue){
    Vue.component('PaintViewerModal', PaintViewerModal);
    Vue.component('PaintEditorModal', PaintEditorModal);
}
export {
  setupComponents
}
