<template>
  <span>
    <span v-if="!paints || !paint">
      <v-chip label class="ma-1 pa-1 px-2">
        <v-progress-circular color="purple" :size="20" :width="2" indeterminate></v-progress-circular>
      </v-chip>
    </span>
    <span v-else>
      <v-chip label class="ma-1 pa-1 px-2" @click="visible = true">
        <v-list-item-avatar class="ma-0 mr-2">
          <v-avatar :color="paint.hex" size="16" />
        </v-list-item-avatar>
        {{ paint.brand }} -
        {{ paint.name }}
      </v-chip>
      <!--  -->
      <v-dialog v-model="visible" persistent :no-click-animation="true">
        <v-card tile>
          <v-card-title class="headline grey lighten-2 mb-3" primary-title>
            {{ paint.name }}
            <v-spacer></v-spacer>
            <v-btn color="teal" @click="visible = false" :loading="loading">
              <v-icon color="white">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text align="center" justify="center" class="grey">
            <v-row class=" pt-2">
              <v-col cols="6" class="grey lighten-1">
                <v-form ref="form">
                  <v-text-field v-model="paint.name" label="Name" required></v-text-field>
                  <v-select v-model="paint.brand" :items="brands" label="Brand" return-object></v-select>
                  <v-text-field v-model="paint.hex" label="Hex" required></v-text-field>
                  <v-text-field v-model="paint.type" label="Type" required></v-text-field>
       <!--            <v-text-field v-model="paint.notes" label="Notes" required></v-text-field>
                  <v-autocomplete v-model="paint.alternatives" :items="paints.filter((item) => item._id != paint._id)" chips auto-select-first clearable label="Alternatives" item-text="name" item-value="_id" multiple>
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.paint" close @click="data.select" :close="false" label class="pl-1">
                        <v-list-item-avatar class="ma-0 mr-2">
                          <v-avatar :color="data.item.hex" />
                        </v-list-item-avatar>
                        {{ data.item.brand }} -
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-chip label class="pl-1">
                        <v-list-item-avatar class="ma-0 mr-2">
                          <v-avatar :color="data.item.hex" />
                        </v-list-item-avatar>
                        {{ data.item.brand }} -
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete> -->
                </v-form>
              </v-col>
              <v-col cols="6" class="black">
                <v-sheet :color="paint.hex" width="100%" height="100%">
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="deletePaint()" v-if="paint._id">Delete</v-btn>
            <v-spacer />
            <v-btn color="primary" @click="updatePaint()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--  -->
    </span>
  </span>
</template>
<script>
import Axios from "axios";
import { getDomainRoot } from "../js/utilities.js";

export default {
  props: ['paints', 'paintId', "getPaintsCallback"],
  name: "PaintEditorModal",
  data() {
    return {
      visible: false,
      loading: false,
      brands: ["Citadel", "Vallejo Game Color"],
    };
  },
  computed: {
    paint() {
      return this.paints.find((item) => {
        return item._id == this.paintId
      })
    }
  },
  created() {},
  methods: {
    updatePaint() {
      console.log("Attempting Update Paint");
      var self = this;
      this.loading = true
      const config = {
        withCredentials: true
      };
      Axios.post(getDomainRoot("/api/recipaint/paints/update"), { ...this.paint }, config)
        .then(function(response) {
          if (response.data.success == true) {
            // self.getPaintsCallback();
          }
          self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    deletePaint() {
      console.log("Attempting Delete Paint");
      var self = this;
      this.loading = true
      const config = {
        withCredentials: true
      };
      Axios.post(getDomainRoot("/api/recipaint/paints/delete"), { ...this.paint }, config)
        .then(function(response) {
          if (response.data.success == true) {
            self.getPaintsCallback();
            self.visible = false
          }
          self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
}
</script>