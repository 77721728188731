<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="#3B1D3C" dark flat>
            <v-toolbar-title>Reset Password</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field label="Login" name="login" prepend-icon="mdi-card-text" type="text" v-model="credentials.username" />
            </v-form>
          </v-card-text>
          <v-card-actions>
             <v-btn text @click="$router.push('/login')" small>
      Back
    </v-btn>
            <v-spacer />
            <v-btn dark color="#3B1D3C" @click="reset()" :loading="loading">Okay</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    source: String
  },

  data: () => ({
    drawer: null,
    loading: false,
    credentials: {
      username: "",
    }
  }),
  methods: {
    reset() {
      this.loading = true;
      this.$store
        .dispatch("user/reset", this.credentials)
        .then((response) => {
          this.loading = false;
          alert("If this user exists, an email has been sent!")
        })
        .catch((error) => {
          alert(error)
          this.loading = false;
        });
    }
  }
};
</script>