<template>
  <span>
    <span v-if="!paints || !paint">
      <v-chip label class="ma-1 pa-1 px-2">
        <v-progress-circular
          color="purple"
          :size="20"
          :width="2"
          indeterminate
        ></v-progress-circular>
      </v-chip>
    </span>
    <span v-else>
      <v-chip label class="ma-1 pa-1 px-2" @click="visible = true">
        <v-list-item-avatar class="ma-0 mr-2">
          <v-avatar :color="paint.hex" />
        </v-list-item-avatar>
        {{ paint.brand }} -
        {{ paint.name }}
      </v-chip>

      <v-dialog
        v-model="visible"
        :no-click-animation="true"
        width="600"
        height="600"
      >
        <v-card class="grey" color="teal" tile width="600" height="600">
          <v-card-title class="headline teal white--text" primary-title>
            <v-spacer></v-spacer>
            {{ paint.name }}
            <v-spacer></v-spacer>
            <v-btn color="rgb(59, 29, 60)" @click="visible = false">
              <v-icon color="white">
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="grey">
            <v-row class=" pt-2">
              <v-col cols="9" class="grey lighten-1">
                <div>Brand: {{ paint.brand }}</div>
                <div>Type: {{ paint.type }}</div>
                <div>Hex: {{ paint.hex }}</div>
                <!-- <div>Notes: {{ paint.notes }}</div> -->
               <!--  <div>Alternatives</div>
                <div v-for="item in paint.alternatives">
                  <PaintViewerModal :paints="paints" :paintId="item" />
                </div> -->
              </v-col>
              <v-col cols="3" class="black">
                <v-sheet :color="paint.hex" width="100%" height="100%">
                </v-sheet>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--  -->
    </span>
  </span>
</template>
<script>
import Axios from "axios";
import { getDomainRoot } from "../js/utilities.js";

export default {
  props: ["paints", "paintName"],
  name: "PaintViewerModal",
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    paint() {
      return this.paints.find((item) => {
        return item.name == this.paintName;
      });
    },
  },
  created() {},
  methods: {},
};
</script>
