import { io } from "socket.io-client";

class SocketioService {
  socket;
  constructor() {}

  connect() {
    this.socket = io(process.env.VUE_APP_api_domain, {
      auth: {
        token: $cookies.get("token"),
      },
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  subscribeToMessages(cb) {
    if (!this.socket) return true;
    this.socket.on("refresh", (msg) => {
      return cb(null, msg);
    });
  }
}

export default new SocketioService();
