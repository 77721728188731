<template>
  <v-app style="background-color: #201521" v-if="getUser">
    <div class="text-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!loading">
      <v-app-bar app dark color="#3B1D3C" clipped-left>
        <v-toolbar-title
          @click="$router.push('/admin').catch(() => {})"
          style="cursor:pointer"
          >XenDelta</v-toolbar-title
        >
        <v-spacer />
        <v-toolbar-items>
          <v-btn text @click="logout">Logout</v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-navigation-drawer
        permanent
        clipped
        expand-on-hover
        app
        color="#170B18"
      >
        <v-list dense dark>
          <v-list-item link exact to="/settings" class="px-2">
            <v-list-item-avatar>
              <v-img :src="getUser.avatar"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title">{{
                getUser.username
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ getUser.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dark nav dense>
          <div
            v-for="item in menuItems"
            v-if="getUser.roles.includes(item.role)"
          >
            <v-list-item link exact :to="item.to" :key="item.name">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <v-divider></v-divider>
        <v-list dark nav dense>
          <v-divider color="white"></v-divider>
          <v-subheader>Apps</v-subheader>
          <div
            v-for="item in appItems"
            v-if="getUser.roles.includes(item.role)"
          >
            <v-list-item link exact :to="item.to" :key="item.name">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <router-view></router-view>
      </v-main>
      <v-snackbar v-model="snackbar" timeout="5000" multi-line>
        <v-row justify="center">{{ snackbarText }}</v-row>
      </v-snackbar>
    </div>
  </v-app>
</template>
<script>
import SocketioService from "../../js/socketio.service.js";
export default {
  name: "InternalFrame",
  metaInfo: {
    title: "XenDelta - Internal",
  },
  props: {},
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    drawer: true,
    menuItems: [
      { text: "Home", icon: "mdi-home", to: "/", role: "user" },
      { text: "Users", icon: "mdi-account", to: "/users", role: "admin" },
    ],
    appItems: [
      {
        text: "Recipaint",
        icon: "mdi-brush",
        to: "/apps/recipaint",
        role: "recipaint",
      },
    ],
  }),
  created() {
    this.$store
      .dispatch("user/loadUser")
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        alert(error);
      });
    SocketioService.subscribeToMessages((err, data) => {
      this.snackbarText = `${data.text}`;
      this.snackbar = true;
    });
  },
  computed: {
    getUser() {
      return this.$store.getters["user/getUser"];
    },
  },
  methods: {
    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>
<style type="text/css">
a {
  text-decoration: none !important;
}

.theme--light.v-divider {
  border-color: white !important;
}
</style>
