<template>
	<v-container>
		<v-card class="elevation-12">
			<v-card-title>
				Dashboard
			</v-card-title>

			<v-container fluid grid-list-xl>
				<v-layout row wrap>
					<v-flex d-flex justify-center>
					</v-flex>
				</v-layout>
			</v-container>

			<v-card-text>
				her at work, the flurry of apologies and stutters. Oh h-hello,
				Miss Kobayashi, it’s Tohru. Please f-forgive the intrusion… She
				has to admit, she likes this better. “It’s fine, I just snuck
				into the break room. What do you need?” “The school called-” Her
				stomach clenches and she’s pretty sure she’s developed an ulcer
				since they moved in. She goes a little numb. “What?! Is she
				okay, what happened?!” “She’s fine, but I don’t know about her
				classmate. They said she was halfway to beating him up when they
				got to her-” What? She can’t even answer immediately, still
				stuck on the idea that Kanna is unwell. It’s not until Tohru
				asks her if she’s still there that she clues in that Kanna teps
				out onto the crowded sidewalk and she yanks her by the sleeve
				into an alley to transform. The flight hurts her back and her
				head today. She’s got a monster headache already and she isn’t
				even there yet. “Did they say anything else about it?” she
				shouts over the rushing wind and the beating of Tohru’s
				impressive wingspan. “Just that they broke it up as fast as they
				could. That the teacher was in the middle of a lesson and looked
				up and she was on him. That she got a few good punches at him
				before they got there.” “She was in class? God, Kanna-” “I hope
				she’s okay. I wish she’d gotten more than a punch in, filthy
				little human whelp thinks he can lay a ha
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
export default {
	props: {},

	data: () => ({})
};
</script>
