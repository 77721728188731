<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="#3B1D3C" dark flat>
            <v-toolbar-title>Signup</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field label="Name" name="name" prepend-icon="mdi-account" type="text" v-model="credentials.name" />
              <v-text-field label="Username" name="username" prepend-icon="mdi-card-text" type="text" v-model="credentials.username" />
              <v-text-field label="Email" name="email" prepend-icon="mdi-email" type="text" v-model="credentials.email" />
              <v-text-field id="password" label="Password" name="password" prepend-icon="mdi-lock" type="password" v-model="credentials.password" />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn dark color="#3B1D3C" @click="signup()">Okay</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    source: String
  },

  data: () => ({
    drawer: null,
    credentials: {
      username: "",
      name: "",
      email: "",
      password: ""
    }
  }),
  methods: {
    signup() {
      this.loading = true;
      this.$store
        .dispatch("user/signup", this.credentials)
        .then((response) => {
          this.loading = false;
          alert("Signup Successful")
          this.$router.push('/login').catch(() => {});
        })
        .catch((error) => {
          alert(error)
          this.loading = false;
        });
    }
  }
};
</script>