<template>
  <v-container>
    <v-toolbar color="teal" dark>
      <v-spacer></v-spacer>
      <v-toolbar-title>Resources</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-img src="../../../assets/paint-slice_01.jpg"></v-img>
    <v-img src="../../../assets/paint-slice_02.jpg"></v-img>
    <v-img src="../../../assets/paint-slice_03.jpg"></v-img>
    <v-img src="../../../assets/paint-slice_04.jpg"></v-img>
    <v-img src="../../../assets/paint-slice_05.jpg"></v-img>
    <v-img src="../../../assets/paint-slice_06.jpg"></v-img>
    <a href="https://www.games-workshop.com/resources/PDF/Downloads/CitadelPaintingSystem.pdf">Citadel Painting System</a>
  </v-container>
</template>
<script>
export default {
  props: {},

  data: () => ({})
};
</script>