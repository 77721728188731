<template>
  <v-container>
    <!--  -->
    <v-snackbar v-if="bannerText" v-model="bannerText" timeout="2000" :color="bannerText.color" elevation="24" tile right bottom>
      <v-row align="center">
        <v-col cols="3" class="pa-0 pl-4">
          <v-avatar color="indigo">
            <v-icon color="white">mdi-check</v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="text-h6">{{ bannerText.title }}</div>
          <div>{{ bannerText.text }}</div>
        </v-col>
      </v-row>
    </v-snackbar>
    <!--  -->
    <v-toolbar color="teal" dark>
      <v-btn color="rgb(59, 29, 60)" class="mr-2" @click="$router.push('/apps/recipaint/recipe/' + $route.params.id)">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>Edit</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="rgb(59, 29, 60)" @click="updateRecipe">
        <v-icon color="white">mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="grey">
      <v-row class=" pt-2">
        <v-col cols="12" class="grey lighten-1">
          <v-form ref="form">
            <v-text-field v-model="recipe.title" label="Title" required></v-text-field>
            <v-text-field v-model="recipe.description" label="Description" required></v-text-field>
            <v-text-field v-model="recipe.notes" label="Notes" required></v-text-field>
          </v-form>
          <!--  -->
          <v-row class="pt-2">
            <v-col>
              <v-card color="teal" class="px-2 pb-2 mb-2">
                <v-card-title class="white--text">Steps <v-spacer></v-spacer>
                  <v-btn color="rgb(59, 29, 60)" class="white--text" @click="newStep">
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>
                <v-data-table :headers="stepHeaders" :items="recipe.steps" v-sortable-data-table @sorted="saveStepOrder" item-key="_id" disable-items-per-page disable-filtering disable-sort hide-default-footer disable-pagination>
                  <template v-slot:item.type="props">
                    <v-edit-dialog :return-value.sync="props.item.type" large persistent>
                      {{ props.item.type }}
                      <template v-slot:input>
                        <v-select v-model="props.item.type" :items="types" label="Type" required return-object></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.description="props">
                    <v-edit-dialog :return-value.sync="props.item.description" large persistent>
                      <div>{{ props.item.description }}</div>
                      <template v-slot:input>
                        <v-text-field v-model="props.item.description" label="Description" required></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.paint="props">
                    <v-edit-dialog :return-value.sync="props.item.paint" large persistent>
                      <v-chip label class="pl-1" v-if="getPaint(props.item.paint)">
                        <v-list-item-avatar class="ma-0 mr-2">
                          <v-avatar :color="getPaint(props.item.paint).hex" />
                        </v-list-item-avatar>
                        {{ getPaint(props.item.paint).brand }} -
                        {{ getPaint(props.item.paint).name }}
                      </v-chip>
                      <template v-slot:input>
                        <v-autocomplete v-model="props.item.paint" :items="paints" chips label="Paints" item-text="name" item-value="name" clearable>
                          <template v-slot:selection="data">
                            <v-chip small v-bind="data.attrs" :input-value="data.selected" close @click="data.select" :close="false" class="pl-0">
                              <v-list-item-avatar class="ma-0 mr-2">
                                <v-avatar :color="data.item.hex" />
                              </v-list-item-avatar>
                              {{ data.item.brand }} -
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <v-chip label class="pl-1">
                              <v-list-item-avatar class="ma-0 mr-2">
                                <v-avatar :color="data.item.hex" />
                              </v-list-item-avatar>
                              {{ data.item.brand }} -
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteStep(item)">
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
              <!--  -->
              <v-card color="teal" class="px-2 pb-2">
                <v-card-title class="white--text">Images <v-spacer></v-spacer>
                  <v-btn color="rgb(59, 29, 60)" class="white--text" @click="newImage">
                    <v-icon color="white">mdi-plus</v-icon>
                  </v-btn>
                </v-card-title>
                <v-data-table :headers="imageHeaders" :items="recipe.images" v-sortable-data-table @sorted="saveImageOrder" item-key="_id" disable-items-per-page disable-filtering disable-sort hide-default-footer disable-pagination>
                  <template v-slot:item.url="props">
                    <v-edit-dialog :return-value.sync="props.item.url" large persistent>
                      <v-avatar tile size="128">
                        <v-img :src="props.item.url" contain></v-img>
                      </v-avatar>
                      <template v-slot:input>
                        <v-text-field v-model="props.item.url" label="Url" required></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.description="props">
                    <v-edit-dialog :return-value.sync="props.item.description" large persistent>
                      <div>{{ props.item.description }}</div>
                      <template v-slot:input>
                        <v-text-field v-model="props.item.description" label="Description" required></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteImage(item)">
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
            <!--  -->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import Axios from "axios";
import { getDomainRoot } from "../../../js/utilities.js";
import Sortable from "sortablejs";
export default {
  name: "RecipaintRecipeEdit",
  components: {},
  data: () => ({
    loading: false,
    bannerText: null,
    types: ["Wash", "Edge", "Highlight", "Airbrush", "Base", "Layer"],
    recipe: {},
    paints: [],
    newImageUrl: "",
    newImageDescription: "",
    stepHeaders: [{
        text: "Type",
        value: "type",
        align: "start",
        divider: true,
        width: "5%",
      },
      {
        text: "Description",
        value: "description",
        align: "start",
        divider: true,
      },
      {
        text: "Paint",
        value: "paint",
        align: "start",
        divider: true,
        width: "5%",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        width: "5%",
      },
    ],
    imageHeaders: [{
        text: "Url",
        value: "url",
        align: "start",
        divider: true,
        width: "5%",
      },
      {
        text: "Description",
        value: "description",
        align: "start",
        divider: true,
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        width: "5%",
      },
    ],
  }),
  created() {
    this.getRecipe();
    this.getPaints();
  },
  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function(event) {
            // console.log(event)
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
  methods: {
    saveStepOrder(event) {
      console.log(this.recipe.steps.map((i) => i.description));
      const movedItem = this.recipe.steps.splice(event.oldIndex, 1)[0];
      this.recipe.steps.splice(event.newIndex, 0, movedItem);
      console.log(this.recipe.steps.map((i) => i.description));
    },
    saveImageOrder(event) {
      console.log(this.recipe.images.map((i) => i.description));
      const movedItem = this.recipe.images.splice(event.oldIndex, 1)[0];
      this.recipe.images.splice(event.newIndex, 0, movedItem);
      console.log(this.recipe.images.map((i) => i.description));
    },
    newImage() {
      this.recipe.images.push({
        url: "",
        description: "",
      });
    },
    newStep() {
      this.recipe.steps.push({
        type: "",
        paint: "",
        description: "",
        done: false,
      });
    },
    deleteImage(item) {
      this.recipe.images.splice(this.recipe.images.indexOf(item), 1);
    },
    deleteStep(item) {
      this.recipe.steps.splice(this.recipe.steps.indexOf(item), 1);
    },
    updateRecipe() {
      console.log("Attempting Update Recipe");
      var self = this;
      // this.loading = true
      const config = {
        withCredentials: true,
      };
      Axios.post(
          getDomainRoot("/api/recipaint/recipes/update"), { ...this.recipe },
          config
        )
        .then(function(response) {
          if (response.data.success == true) {
            self.bannerText = {
              title: "Success",
              text: "Recipe Updated",
              color: "primary",
            };
            self.getRecipe();
          }
          // self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getRecipe() {
      console.log("Attempting Get Recipe");
      var self = this;
      this.loading = true;
      const config = {
        withCredentials: true,
        params: {
          id: this.$route.params.id,
        },
      };
      Axios.get(getDomainRoot("/api/recipaint/recipe"), config)
        .then(function(response) {
          if (response.data.success == true) {
            self.recipe = response.data.recipe;
          }
          self.loading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getPaints() {
      console.log("Attempting Get Paints");
      var self = this;
      this.loading = true;
      const config = {
        withCredentials: true,
      };
      Axios.get(getDomainRoot("/api/recipaint/paints"), config)
        .then(function(response) {
          if (response.data.success == true) {
            self.paints = response.data.paints;
          }
          self.loading = false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getPaint(target_name) {
      return this.paints.find((idx) => {
        return target_name == idx.name;
      });
    },
  },
};
</script>