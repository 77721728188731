<template>
  <v-app style="background-color: #201521">
    <div class="text-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!loading">
      <v-app-bar app dark color="#3B1D3C" clipped-left>
        <v-toolbar-title @click="$router.push('/admin').catch(()=>{});" style="cursor:pointer">Recipaint</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text @click="logout">Logout</v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <v-navigation-drawer permanent clipped expand-on-hover app color="#170B18">
        <v-list dense dark>
          <v-list-item link exact to="/admin/settings" class="px-2">
            <v-list-item-avatar>
              <v-img :src="getUser.avatar"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="title">{{getUser.username}}</v-list-item-title>
              <v-list-item-subtitle>{{getUser.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link exact to="/admin">
            <v-list-item-icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Back</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dark nav dense>
          <div v-for="item in menuItems">
            <v-list-item link exact :to="item.to" :key="item.name">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  item.text
                  }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <router-view></router-view>
      </v-main>
    </div>
  </v-app>
</template>
<script>
  export default {
  name: "RecipaintFrame",
    metaInfo: {
    title: "XenDelta - Recipaint",
  },
  props: {},
  data: () => ({
    loading: true,
    drawer: true,
    menuItems: [
      { text: "Home", icon: "mdi-home", to: "/apps/recipaint" },
      { text: "Resources", icon: "mdi-file-document", to: "/apps/recipaint/resources" },
      { text: "Paints", icon: "mdi-format-color-fill", to: "/apps/recipaint/paints",}, 
      { text: "Recipes", icon: "mdi-format-list-bulleted", to: "/apps/recipaint/recipes",},
      { text: "Guides", icon: "mdi-clipboard-list", to: "/apps/recipaint/guides",}
    ]
  }),
  created() {
    this.$store
      .dispatch("user/loadUser")
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        alert(error);
      });

  },
  computed: {
    getUser() {
      return this.$store.getters["user/getUser"];
    },
  },
  methods: {
    logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          window.location.href = process.env.VUE_APP_live_domain;
        })
        .catch(error => {
          alert(error);
        });
    }
  }
};
</script>
<style type="text/css">
a {
  text-decoration: none !important;
}

.theme--light.v-divider {
  border-color: white !important;
}
</style>