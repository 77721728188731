<template>
  <v-container>
    <v-card class="elevation-12">
      <v-toolbar color="teal" dark>
        <v-spacer></v-spacer>
        <v-toolbar-title>Dashboard</v-toolbar-title>
        <v-spacer></v-spacer>
                <v-btn color="rgb(59, 29, 60)" @click="fetchPaints"> Fetch Paints </v-btn>

      </v-toolbar>
      <v-container fluid grid-list-xl>
        <v-layout row wrap>
          <v-flex d-flex justify-center>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-text>
        Kanna’s eyes go round and she pales and shrinks when she sees her. She thought Tohru was coming to get her and she looks ready to dissolve into the floor when she finds that Kobayashi has left work to come here. It’s more than a little heartbreaking when her little eyes well up and she looks at the ground and swings her legs.
        She’s pulled into a meeting with the principal and Kanna, which is expected.
        “We’re not sure how it started, just that her teacher looked up and she pulled him out of his desk onto the floor and started hitting him,” the principal tells her, her tone both somehow clipped and nonplussed. She turns to address Kanna. “Kanna, can you tell us what happened? Did he say something to you?”
        She won’t look at either of them. Just sits with her mouth clamped shut and swings her legs like that.
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
  import Axios from "axios";
import { getDomainRoot } from "../../../js/utilities.js";
export default {
  props: {},

  data: () => ({}),
  created() {
    // this.fetchPaints();
  },
  methods: {

    fetchPaints() {
      console.log("Attempting Fetch Paints");
      var self = this;
      const config = {
        withCredentials: true
      };
      Axios.get(getDomainRoot("/api/recipaint/fetch"), config)
        .then(function(response) {
          if (response.data.success == true) {
            console.log(response.data)
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },

  }
};
</script>