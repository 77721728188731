<template>
  <span>
    <v-container v-if="loading" fill-height fluid>
      <v-row>
        <v-col align="center" justify="center">
          <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-toolbar color="teal" dark>
        <v-spacer></v-spacer>
        <v-toolbar-title>Recipes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="rgb(59, 29, 60)" @click="newRecipe">
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-dialog v-model="visible" width="600px" persistent :no-click-animation="true">
        <v-card tile>
          <v-card-title class="headline grey lighten-2 mb-3" primary-title>
            New Recipe
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="visible = false">Close</v-btn>
          </v-card-title>
          <v-card-text align="center" justify="center" class="grey">
            <v-row class=" pt-2">
              <v-col cols="12" class="grey lighten-1">
                <v-form ref="form">
                  <v-text-field v-model="newName" label="Name" required></v-text-field>
                  <v-text-field v-model="newDescription" label="Description" required></v-text-field>
                  <!-- <v-text-field v-model="selected.notes" label="Notes" required></v-text-field> -->
                </v-form>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="saveNewRecipe()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container class="grey">
        <v-row justify="center" class="pa-2">
          <div v-for="item in recipes">
            <v-card class="ma-1 black" width="300" height="300" @click="$router.push('recipe/'+item._id)">
              <v-img v-if="item.images.length > 0" height="300" class="grey white--text align-start" :src="item.images[0].url">
                <div style="background:rgba(0,0,0,0.8);">
                  <v-card-title>{{ item.title }}</v-card-title>
                  <v-card-subtitle class="white--text">
                    {{ item.description }}
                  </v-card-subtitle>
                </div>
              </v-img>
              <v-img v-else width="300" height="300" class=" grey white--text align-start">
                <div style="background:rgba(0,0,0,0.5);">
                  <v-card-title>{{ item.title }}</v-card-title>
                  <v-card-subtitle class="white--text">
                    {{ item.description }}
                  </v-card-subtitle>
                </div>
              </v-img>
            </v-card>
          </div>
        </v-row>
      </v-container>
    </v-container>
  </span>
</template>
<script>
import Axios from "axios";
import { getDomainRoot } from "../../../js/utilities.js";

export default {
  name: "RecipaintRecipes",
  components: {},
  data: () => ({
    loading: true,
    visible: false,
    newName: "",
    newDescription: "",
    recipes: [],
  }),
  created() {
    this.getRecipes();
  },
  computed: {},
  methods: {
    newRecipe() {
      this.newName = ""
      this.newDescription = ""
      this.visible = true;
    },
    getRecipes() {
      console.log("Attempting Get Recipes");
      var self = this;
      this.loading = true
      const config = {
        withCredentials: true
      };
      Axios.get(getDomainRoot("/api/recipaint/recipes"), config)
        .then(function(response) {
          if (response.data.success == true) {
            self.recipes = response.data.recipes
          }
          self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    saveNewRecipe() {
      console.log("Attempting New Recipe");
      var self = this;
      this.loading = true
      const config = {
        withCredentials: true
      };
      Axios.post(getDomainRoot("/api/recipaint/recipes/new"), { title: this.newName, description: this.newDescription }, config)
        .then(function(response) {
          if (response.data.success == true) {
            self.newName = ""
            self.newDescription = ""
            self.getRecipes();
            self.visible = false
          }
          self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });

    }
  },
};
</script>