import Axios from "axios";
import { getDomainRoot } from "../../js/utilities.js";
import SocketioService from "../../js/socketio.service.js";

const state = {
  user: null
};

const getters = {
  getUser: state => {
    return state.user;
  },
  getSettings: state => {
    return state.user.settings;
  },
  isLoggedIn: state => {
    return state.user != null;
  }
};

const mutations = {
  // auth: (state, user) => {
  //   state.user = user;
  // },
  setUser: (state, user) => {
    state.user = user;
  },
  logout: state => {
    state.user = null;
  }
};

const actions = {
  loadUser: context => {
    return new Promise((resolve, reject) => {
      console.log("Attempting Get User");
      const config = {
        withCredentials: true
      };
      Axios.get(getDomainRoot("/api/user"), config)
        .then(function(response) {
          if (response.data.success == true) {
            context.commit("setUser", response.data.user);
            resolve(true);
          } else {
            reject(response.data.message);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  logout: context => {
    return new Promise((resolve, reject) => {
      SocketioService.disconnect();
      $cookies.remove('token')
      context.commit("logout");
      resolve();
    });
  },
  auth: context => {
    return new Promise((resolve, reject) => {
      console.log("Authenticating Re-entry");
      Axios.post(
        getDomainRoot("/api/auth"),
        {},
        {
          withCredentials: true
        }
      )
        .then(function(response) {
          if (response.data.success) {
            $cookies.set('token',response.data.token)
            resolve(true);
          } else {
            $cookies.remove('token')
            context.commit("logout");
            resolve(response.data.message);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    });
  },
  reset: (context, credentials) => {
    return new Promise((resolve, reject) => {
      console.log("Attempting Reset");
      Axios.post(getDomainRoot("/api/reset"), {
        username: credentials.username,
      })
        .then(function(response) {
          if (response.data.success == true) {
            resolve(true);
          } else {
            reject(response.data.message);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  login: (context, credentials) => {
    return new Promise((resolve, reject) => {
      console.log("Attempting Login");
      Axios.post(getDomainRoot("/api/login"), {
        username: credentials.username,
        password: credentials.password
      })
        .then(function(response) {
          if (response.data.success == true) {
            $cookies.set('token',response.data.token);
            resolve(true);
          } else {
            reject(response.data.message);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  signup: (context, credentials) => {
    return new Promise((resolve, reject) => {
      console.log("Attempting Signup");
      Axios.post(getDomainRoot("/api/signup"), {
        username: credentials.username,
        name: credentials.name,
        email: credentials.email,
        password: credentials.password
      })
        .then(function(response) {
          if (response.data.success == true) {
            // context.commit("signup");
            resolve(true);
          } else {
            reject(response.data.message);
          }
        })
        .catch(function(error) {
          alert("Signup Failed");
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
