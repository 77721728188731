@@ -0,0 +1,111 @@
<template>
  <v-container>
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Profile</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container justify="start" class="grey" v-if="!loading">
      <v-card width="100%" tile>
        <v-img height="400" src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg">
          <v-row align="end" class="fill-height">
            <v-col align-self="start" class="pa-0 pl-6 pt-3" cols="12">
              <v-avatar class="profile" color="grey" size="164">
                <v-img :src="avatar"></v-img>
              </v-avatar>
            </v-col>
            <v-col class="py-0">
              <v-list-item color="rgba(0, 0, 0, .4)" dark>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{getUser.username}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{getUser.name}}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{getUser.email}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-chip v-for="role in getUser.roles" label class="ma-2" color="black" text-color="white" :key="role">
                  {{role}}
                </v-chip>
              </v-list-item>
            </v-col>
          </v-row>
        </v-img>
      </v-card>
      <v-card width="100%" tile>
                <v-card-title>
          Update User
        </v-card-title>
        <v-card-text>
      <v-text-field label="Avatar" v-model="avatar"></v-text-field>
      <br>
      <v-text-field label="Old Password" v-model="old_password"></v-text-field>
      <v-text-field label="New Password" v-model="new_password"></v-text-field>
      <v-btn @click="updateUser()">
        Update
      </v-btn>
    </v-card-text>
    </v-card>
    </v-container>
  </v-container>
</template>
<script>
import Axios from "axios";
import { getDomainRoot } from "../../js/utilities.js";
export default {
  props: {},
  data: () => ({
    loading: false,
    avatar: "",
    old_password: "",
    new_password: ""
  }),
  mounted: function() { this.avatar = this.$store.getters["user/getUser"].avatar },
  computed: {
    getUser() {
      return this.$store.getters["user/getUser"];
    },
  },
  methods: {
        logout() {
      this.$store
        .dispatch("user/logout")
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          alert(error);
        });
    },
    updateUser() {
      console.log("Attempting Update Avatar");
      var self = this;
      const config = {
        withCredentials: true
      };
      Axios.post(getDomainRoot("/api/user/update"), { avatar: this.avatar, old_password: this.old_password, new_password: this.new_password }, config)
        .then(function(response) {
          if (response.data.success == true) {
            self.$store
              .dispatch("user/loadUser")
              .then(() => {
                self.loading = false;
                self.old_password = ""
                self.new_password = ""
                alert("Updated!");
              })
              .catch((error) => {
                alert(error);

              });
          } else {
            alert(response.data.message);
            self.logout();
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>