<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>
import SocketioService from "./js/socketio.service.js";
export default {
    data() {
        return {};
    },
    beforeUnmount() {
        SocketioService.disconnect();
    },
    computed: {},
    methods: {},
};
</script>