<template>
  <v-app id="inspire">
    <v-app-bar app dark color="#3B1D3C">
      <v-toolbar-title>
        XenDelta
      </v-toolbar-title>
      <v-toolbar-items class="ml-3">
        <v-btn text to="/">Home</v-btn>
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items>
        <v-btn text to="login">Login</v-btn>
        <v-btn text to="signup">Signup</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main style="background-color: #201521">
      <router-view></router-view>
    </v-main>
    <v-footer color="#170B18" app>
      <span class="white--text">&copy; XenDelta 2019</span>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  metaInfo: {
    title: "XenDelta",
  },
  props: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {}
};
</script>