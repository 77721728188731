<template>
  <v-container>
    <!--  -->
    <v-snackbar v-if="bannerText" v-model="bannerText" timeout="2000" :color="bannerText.color" elevation="24" tile right bottom>
      <v-row align="center">
        <v-col cols="3" class="pa-0 pl-4">
          <v-avatar color="indigo">
            <v-icon color="white">mdi-check</v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="9" class="pa-0">
          <div class="text-h6"> {{bannerText.title}} </div>
          <div> {{ bannerText.text }} </div>
        </v-col>
      </v-row>
    </v-snackbar>
    <!--  -->
    <v-toolbar color="teal" dark>
      <v-btn color="rgb(59, 29, 60)" class="mr-2" @click="$router.push('/apps/recipaint/recipes')">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{recipe.title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="rgb(59, 29, 60)" class="ml-2" @click="deleteRecipe">
        <v-icon color="white">mdi-delete</v-icon>
      </v-btn>
      <v-btn color="rgb(59, 29, 60)" class="ml-2" @click="$router.push('/apps/recipaint/recipe/' + $route.params.id + '/edit/')">
        <v-icon color="white">mdi-pencil</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="grey">
      <v-carousel show-arrows-on-hover height="500" align="center" justify="center">
        <v-carousel-item v-for="(slide, index) in recipe.images" :key="index">
          <v-img width="500" height="400" contain :src="recipe.images[index].url" class="black" @click="openImage(recipe.images[index].url)">
          </v-img>
          <div class="text-subtitle">
            {{ recipe.images[index].description }}
          </div>
        </v-carousel-item>
      </v-carousel>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Description</v-card-title>
            <v-card-text class="black--text">
              {{ recipe.description }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Notes</v-card-title>
            <v-card-text class="black--text">
              {{ recipe.notes }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!--  -->
      <v-row v-for="step in recipe.steps" :key="step._id" align="center" justify="center">
        <v-col cols="12">
          <v-card :style="{ opacity: step.done ? 0.2 : 1.0}">
            <v-card-title>
              <v-btn fab x-small dense class="ma-2" color="teal" @click="toggleStep(step)">
                <v-icon color="white">
                  {{step.done? 'mdi-close' : 'mdi-check' }}
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              {{ step.type }}
              <v-spacer></v-spacer>
              <PaintViewerModal :paints="paints" :paintName="step.paint" />
            </v-card-title>
            <v-card-text class="black--text">
              {{ step.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
  import Axios from "axios";
import { getDomainRoot } from "../../../js/utilities.js";

export default {
  name: "RecipaintRecipie",
  components: {},
  data: () => ({
    loading: false,
    bannerText: null,
    recipe: {},
    paints: [],
  }),
  created() {
    this.getRecipe();
    this.getPaints();
  },
  computed: {


  },
  methods: {
    openImage(url) {
      window.open(url, '_blank')
    },
    toggleStep(step) {
      step.done = !step.done
      this.updateRecipe()
    },
    getRecipe() {
      console.log("Attempting Get Recipe");
      var self = this;
      this.loading = true
      const config = {
        withCredentials: true,
        params: {
          id: this.$route.params.id
        }
      };
      Axios.get(getDomainRoot("/api/recipaint/recipe"), config)
        .then(function(response) {
          if (response.data.success == true) {
            self.recipe = response.data.recipe
          }
          self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },
        updateRecipe() {
      console.log("Attempting Update Recipe");
      var self = this;
      // this.loading = true
      const config = {
        withCredentials: true
      };
      Axios.post(getDomainRoot("/api/recipaint/recipes/update"), { ...this.recipe }, config)
        .then(function(response) {
          if (response.data.success == true) {
            self.bannerText = {
              title: "Success",
              text: "Recipe Updated",
              color: 'primary'
            }
            self.getRecipe();
          }
          // self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },
      deleteRecipe() {
      console.log("Attempting Delete Recipe");
      var self = this;
      // this.loading = true
      const config = {
        withCredentials: true
      };
      Axios.post(getDomainRoot("/api/recipaint/recipes/delete"), { _id: this.recipe._id }, config)
        .then(function(response) {
          if (response.data.success == true) {
            self.bannerText = {
              title: "Success",
              text: "Recipe Deleted",
              color: 'primary'
            }
            self.$router.push('/apps/recipaint/recipes')
          }
          // self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getPaints() {
      console.log("Attempting Get Paints");
      var self = this;
      this.loading = true
      const config = {
        withCredentials: true
      };
      Axios.get(getDomainRoot("/api/recipaint/paints"), config)
        .then(function(response) {
          if (response.data.success == true) {
            self.paints = response.data.paints
          }
          self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getPaint(target_id) {
      return this.paints.find((idx) => {
        return target_id == idx._id
      })
    },
  },
};
</script>
<style type="text/css">
.theme--light.v-divider {
  border-color: black !important;
}
</style>