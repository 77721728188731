import Vue from "vue";
import App from "./App.vue";
import Router from "vue-router";
import VueCookies from "vue-cookies";
import VueMeta from 'vue-meta';
import vuetify from "./plugins/vuetify";
import store from "./store";
import axios from "axios";
import { setupComponents } from "./js/setup-components";
//
import ExternalFrame from "./sections/external/ExternalFrame.vue";
import Landing from "./sections/external/Landing.vue";
import Login from "./sections/external/Login.vue";
import Signup from "./sections/external/Signup.vue";
import Reset from "./sections/external/Reset.vue";
//
import InternalFrame from "./sections/internal/InternalFrame.vue";
import Dashboard from "./sections/internal/Dashboard.vue";
import Settings from "./sections/internal/Settings.vue";
import Users from "./sections/internal/Users.vue";
//
import RecipaintFrame from "./sections/apps/recipaint/RecipaintFrame.vue";

import RecipaintDashboard from "./sections/apps/recipaint/Dashboard.vue";
import RecipaintGuides from "./sections/apps/recipaint/Guides.vue";
import RecipaintResources from "./sections/apps/recipaint/Resources.vue";
import RecipaintPaints from "./sections/apps/recipaint/Paints.vue";
import RecipaintRecipes from "./sections/apps/recipaint/Recipes.vue";
import RecipaintRecipe from "./sections/apps/recipaint/Recipe.vue";
import RecipaintRecipeEdit from "./sections/apps/recipaint/Recipe_edit.vue";

const routes = [{
    path: "/",
    component: ExternalFrame,
    children: [{
        path: "",
        meta: { reqAuth: false },
        component: Landing,
      },
      {
        path: "login",
        meta: { reqAuth: false },
        component: Login,
      },
      {
        path: "signup",
        meta: { reqAuth: false },
        component: Signup,
      },
      {
        path: "reset",
        meta: { reqAuth: false },
        component: Reset,
      },
    ],
  },
  {
    path: "/",
    component: InternalFrame,
    children: [{
        path: "dashboard",
        meta: { reqAuth: true },
        component: Dashboard,
      },
      {
        path: "settings",
        meta: { reqAuth: true },
        component: Settings,
      },
      {
        path: "users",
        meta: { reqAuth: true },
        component: Users,
      },
    ],
  },

  {
    path: "/apps/recipaint",
    component: RecipaintFrame,
    children: [{
        path: "",
        meta: { reqAuth: true },
        component: RecipaintDashboard,
      },
      {
        path: "paints",
        name: "paints",
        meta: { reqAuth: true },
        component: RecipaintPaints,
      },
      {
        path: "resources",
        name: "resources",
        meta: { reqAuth: true },
        component: RecipaintResources,
      },
      {
        path: "guides",
        name: "guides",
        meta: { reqAuth: true },
        component: RecipaintGuides,
      },
      {
        path: "recipes",
        name: "Recipaint Recipes",
        meta: { reqAuth: true },
        component: RecipaintRecipes,
      },
      {
        path: "recipe/:id",
        name: "Recipaint Recipe",
        meta: { reqAuth: true },
        component: RecipaintRecipe,
      },
      {
        path: "recipe/:id/edit",
        name: "Recipaint Recipe Edit",
        meta: { reqAuth: true },
        component: RecipaintRecipeEdit,
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];
Vue.config.productionTip = false;
setupComponents(Vue);
Vue.use(Router);
Vue.use(VueMeta);
const domain =
  process.env.NODE_ENV === "development" ? "localhost" : "xendelta.com";
Vue.use(VueCookies, {
  expires: "7d",
  domain: domain,
  secure: "true",
  sameSite: "Lax",
});
const router = new Router({
  routes,
  mode: "history",
  saveScrollPosition: true,
  base: process.env.BASE_URL,
});
store.dispatch("user/auth").then(() => {
  new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = $cookies.get("token") != null;
  if (!isAuthenticated && to.meta.reqAuth == false) {
    next();
  }
  if (!isAuthenticated && to.meta.reqAuth == true) {
    next("/");
  }
  if (isAuthenticated && to.meta.reqAuth == false) {
    next("/dashboard/");
  }
  if (isAuthenticated && to.meta.reqAuth == true) {
    next();
  }
});