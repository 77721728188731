import Axios from "axios";

export function getDomainMedia(id) {
	return getDomainRoot("/api/admin/media/" + id);
}

export function getDomainMediaThumb(id) {
	return getDomainRoot("/api/admin/media/" + id + "?thumb");
}

export function getDomainRoot(path) {
	return process.env.VUE_APP_api_domain + path;
}
