<template>
  <span>
    <v-container v-if="loading" fill-height fluid>
      <v-row>
        <v-col align="center" justify="center">
          <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-toolbar color="teal" dark>
        <v-spacer></v-spacer>
        <v-toolbar-title>Paints</v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn color="rgb(59, 29, 60)" @click="newPaint"> <v-icon color="white">mdi-plus</v-icon> </v-btn> -->
      </v-toolbar>
      <v-container class="grey">
        <v-select v-model="selected_brand" :items="brands" label="Brands" return-object></v-select>

      
        <span v-for="(item, index) in filteredBrands(selected_brand)">
          <PaintViewerModal :paints="paints" :paintName="item.name"/>
        </span>

      </v-container>
    </v-container>
  </span>
</template>
<script>
import Axios from "axios";
import { getDomainRoot } from "../../../js/utilities.js";

export default {
  name: "Paints",
  components: {},
  data: () => ({
    visible: false,
    loading: true,
    selected: {},
    selected_brand: "Citadel",
    // brands: ["Citadel", "Vallejo Game Color"],
    paints: [],
  }),
  computed: {},
  created() {
    this.getPaints();
  },
  methods: {
    filteredBrands(brand) {
      return this.paints.filter(
        (item) => item.brand == brand
      );
    },
    // newPaint() {
    //   const newPaint = {
    //     id: null,
    //     name: "New Paint",
    //     brand: "Citadel",
    //     hex: "#FFFFF",
    //     alternatives: [],
    //   };
    //   console.log("Attempting Create New Paint");
    //   var self = this;
    //   this.loading = true
    //   const config = {
    //     withCredentials: true
    //   };
    //   Axios.post(getDomainRoot("/api/recipaint/paints/update"), { ...newPaint }, config)
    //     .then(function(response) {
    //       if (response.data.success == true) {
    //         self.getPaints();
    //       }
    //       self.loading = false
    //     })
    //     .catch(function(error) {
    //       console.log(error);
    //     });
    // },
    getPaints() {
      console.log("Attempting Get Paints");
      var self = this;
      this.loading = true
      const config = {
        withCredentials: true
      };
      Axios.get(getDomainRoot("/api/recipaint/paints"), config)
        .then(function(response) {
          if (response.data.success == true) {
            self.paints = response.data.paints
            self.brands = response.data.brands
          }
          self.loading = false
        })
        .catch(function(error) {
          console.log(error);
        });
    },

  },
};
</script>